export const RoundStatus = {
  INVALID: 0,
  PENDING: 'pending',
  RUNNING: 'running',
  FINISHED: 'finished',
}

export const SortDirection = {
  ASC: 'ascend',
  DESC: 'descend'
}

export const Tiebreak = {
  point: {
    key: 'point',
    name: 'Point',
    path: 'label.point',
    priorityFixed: true,
    direction: SortDirection.DESC,
  },
  solkoff: {
    key: 'solkoff',
    name: 'Solkoff',
    path: 'label.solkoff',
    direction: SortDirection.DESC,
  },
  oppMwRate: {
    key: 'oppMwRate',
    name: 'OppMW%',
    path: 'label.oppMwRate',
    direction: SortDirection.DESC,
    format: 'rate',
  }
}

export const ThreeSignal = {
  OK: 'ok',
  WARN: 'warn',
  BAD: 'bad',
}
