<template>
  <div class="home container">
    <div class="summary">
      <h3>{{ $t('label.summary') }}</h3>
      <table class="table">
        <colgroup>
          <col class="col-key">
          <col>
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">{{ $t('label.status') }}</th>
            <td>{{ $t(statusPath, { count: roundCount }) }}</td>
          </tr>
          <tr>
            <th scope="row">{{ $t('label.players') }}</th>
            <td>{{ numPlayers }} / {{ maxPlayers }} </td>
          </tr>
          <tr>
            <th scope="row">{{ $t('label.shareLink') }}</th>
            <td>
              {{ shareLink }}
            </td>
          </tr>
          <tr>
            <th scope="row">QR</th>
            <td>
              <span v-html="qrImg"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="isManager" class="about">
      <h3>{{ $t('label.usage') }}</h3>
      <ol>
        <li v-html="$t('message.usage.rule')"></li>
        <li v-html="$t('message.usage.checkin')"></li>
        <li v-html="$t('message.usage.table')"></li>
        <li v-html="$t('message.usage.leaderboard')"></li>
      </ol>

    </div>

    <p class="copylight">
      <small>&copy; 2020-2022 by RZNA</small>
    </p>
  </div>
</template>
<script>
import QRCode from 'qrcode'
import { RoundStatus } from '@/utils/constraints'
import { mapState } from 'vuex'

export default {
  name: "Entrance",
  data() {
    return {
      qrData: null,
    }
  },
  async created() {
    await this.setQrData()
  },
  computed: {
    ...mapState('event', {
      roundCount : state => state.round.count,
      roundStatus: state => state.round.status,
      numPlayers: state => state.player.list.length,
      maxPlayers: state => state.rule.maxPlayer,
      phrase: state => state.phrase
    }),
    lang() {
      return this.$route.params.lang
    },
    isManager() {
      return this.$route.matched.some(r => r.meta.isWritable)
    },
    statusPath() {
      return this.roundCount == 0 ? "message.checkin"
          : this.roundStatus === RoundStatus.RUNNING ? "message.roundRunning"
          : "message.roundFinished"
    },
    shareLink() {
      return window.location.origin + window.location.pathname + '#/' + this.lang + '/view/' + this.phrase + '/'
    },
    qrImg() {
      if(this.qrData) {
        return `<img src="${this.qrData}">`
      } else {
        return ""
      }
    },
  },
  methods: {
    async generateShareLinkQrCode() {
      try {
        return await QRCode.toDataURL(this.shareLink, { errorCorrectionLevel: 'M' })
      } catch(err) {
        console.error(err)
      }
    },
    async setQrData() {
      this.qrData = await this.generateShareLinkQrCode()
    }
  },
}
</script>
<style scoped>
.summary .col-key {
  width: 8rem;
}
</style>
<i18n>
{
  "en": {
    "label": {
      "summary": "Summary",
      "status": "Status",
      "players": "Players",
      "about": "About",
      "usage": "Usage",
      "shareLink": "Share Link"
    },
    "message": {
      "checkin": "Checking in",
      "roundRunning": "Round {count} is running",
      "roundFinished": "Round {count} is finished",
      "about": "The supporting tool for running {0}",
      "swissDraw": "Swiss-system tournament",
      "usage": {
        "rule": "@:label.rule - decide tournament rules",
        "checkin": "@:label.checkin - register players",
        "table": "@:label.table - record each result of plays",
        "leaderboard": "@:label.leaderboard - check player places"
      },
      "sourcePublish": "The source code is available on Bitbucket.",
      "issueAnnounce": " If some problems with this tool, please let me know via {0}.",
      "issueTracker": "issue tracker on Bitbucket"
    },
    "url": {
      "swissDraw": "https://en.wikipedia.org/wiki/Swiss-system_tournament"
    }
  },
  "ja": {
    "label": {
      "summary": "概要",
      "status": "状態",
      "players": "選手",
      "about": "このツールについて",
      "usage": "使い方",
      "shareLink": "共有リンク"
    },
    "message": {
      "checkin": "チェックイン中",
      "roundRunning": "{count}回戦進行中",
      "roundFinished": "{count}回戦終了",
      "about": "{0}の運営を支援するツールです。",
      "swissDraw": "スイスドロー大会",
      "usage": {
        "rule": "@:label.rule - 大会のルールを定めます。",
        "checkin": "@:label.checkin - 選手を登録します。",
        "table": "@:label.table - 各試合の結果を記録します。",
        "leaderboard": "@:label.leaderboard - 順位を確認します。"
      },
      "sourcePublish": "本ツールのソースコードはBitbucket上にて公開しています。",
      "issueAnnounce": "お気づきの点がありましたら、{0}よりお気軽にお知らせください。",
      "issueTracker": "課題トラッカー"
    },
    "url": {
      "swissDraw": "https://ja.wikipedia.org/wiki/スイス式トーナメント"
    }
  }
}
</i18n>
